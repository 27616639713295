var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":"Note administrative","size":"lg","color":"danger","show":_vm.modalStatus},on:{"update:show":function($event){_vm.modalStatus=$event}}},[_c('CDataTable',{staticClass:"my-2 table-borderless",attrs:{"hover":"","responsive":false,"items":_vm.itemsTache,"fields":_vm.tableFields,"header":false,"loading":_vm.isLoading,"items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var item = ref.item;
return _c('td',{staticStyle:{"min-width":"10rem"}},[_c('CLink',{staticClass:"text-decoration-none ",attrs:{"to":{
            path: '/projets/' + item.idcontents,
          }}},[_c('div',{on:{"click":_vm.evModalLast}},[_vm._v(" "+_vm._s(item.titre)+" "),(item.privaty == '1')?_c('CBadge',{attrs:{"color":"danger","position":"top-start","shape":"pill"}},[_vm._v(" Privé ")]):_vm._e()],1),_c('div',{staticClass:"small text-muted mt-1"},[_vm._v(" Crée le: "+_vm._s(item.created_at)+" ")])])],1)}},{key:"usage",fn:function(ref){
          var item = ref.item;
return _c('td',{attrs:{"width":"300"}},[_vm._v(" "+_vm._s(_vm.progressItem(item))+" "),(item.val && item.max)?_c('CProgress',{staticClass:"progress-xs",staticStyle:{"height":"10px"},attrs:{"animated":item.status === '1' || item.status === '3' ? false : true,"showPercentage":"","striped":item.status === '1' || item.status === '3' ? false : true,"max":item.max,"value":item.val,"color":_vm.color(item.val, item.max)}}):_vm._e()],1)}},{key:"activity",fn:function(ref){
          var item = ref.item;
return _c('td',{},[_c('CRow',{staticClass:"ml-0 d-flex justify-content-arround flex-nowrap"},[_c('CLink',{staticClass:"mx-3 text-decoration-none",attrs:{"color":"primary","variant":"ghost","shape":"pill","size":"sm","to":{
            path: '/projets/' + item.idcontents,
          }}},[_c('div',{staticClass:"small text-muted mt-1"},[_vm._v(" Crée le: "+_vm._s(item.created_at)+" ")])])],1)],1)}}])}),_c('CRow',[_c('CCol',{staticClass:" pl-4 mb-n4",attrs:{"col":"10"}},[_c('p',[_vm._v("Voir toutes les "),_c('span',{on:{"click":_vm.evModalLast}},[_c('CLink',{staticClass:"text-danger",attrs:{"to":{
            path: '/projets/988',
          }}},[_vm._v("notes administrative "),_c('CIcon',{staticClass:" mx-1",attrs:{"name":"cilShare","size":"sm"}})],1)],1)])])],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"d-flex justify-content-end mr-3"},[_c('CLink',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Actualiser'),expression:"'Actualiser'"}]},[_c('CButton',{attrs:{"size":"sm","shape":"pill","color":"secondary"},on:{"click":function($event){return _vm.LoadTacheData(false)}}},[_c('CIcon',{attrs:{"name":"cil-reload","size":"sm"}})],1)],1)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }